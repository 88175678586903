import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const FileDownload = styled.a`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.6rem;
`

const Registration = props => {
  return (
    <>
      <StyledBasePortableText>
        <h2>登録の注意点</h2>
        <p>申込・決済時にお送りした<b>お申し込み番号</b>(0123-4567-8901-234)をご用意ください。<br />
          support@nyikuei.orgからお送りした、件名「お申し込み完了のお知らせ - NY育英オンラインサマースクール&キャンプ 2020」のメールに記載されております。
        </p>
        <p><b>定員について</b><br />
          クラス名の先頭にある ○、△、×にて状況がご確認いただけます。<br />
          <b>○</b>: 定員に余裕があります。<br />
          <b>△</b>: 定員間近。ご登録いただいても定員に達していて、他のクラスへの変更が必要になる場合がございます。<br />
          <b>×</b>: 定員に達したため登録できません。<br />
          定員一覧は下記ウェブサイトからもご覧いただけます。<br />
          <ExternalLink href='https://airtable.com/shr23f1rmtBRfCMyy' target='_blank'>定員状況へ</ExternalLink>
        </p>
        <p>変更はフォームにて受け付けておりますが定員があるためご希望に添えない場合がございます。登録前によくご確認ください。</p>
        <p>複数週お申し込みの場合は<b>週毎のご登録</b>が必要となっております。<br />毎週木曜日に翌週のクラス変更・ウェイティングお申し込みを締め切ります。</p>
        <p>クラス登録済みのお申し込み番号を入力して<b>時間割とGoogle Meet URLの確認</b>ができます。</p>

        <h2>ウェイティング・変更について</h2>
        <p>定員に達したクラスは選択ができなくなっております。<br />
          ウェイティングをご希望の方は、第二希望(または第三希望以降)のクラスをご登録の後、下記フォームからお申し込みください。
        </p>
        <p>クラス変更はフォームにて受け付けておりますが定員があるためご希望に添えない場合がございます。<br />
          ご希望の場合は下記フォームよりお問い合わせください。<br />
          毎週木曜日に翌週のクラス変更・ウェイティングお申し込みを締め切ります。
        </p>

        <ExternalLink href='https://airtable.com/shrrd89YcfyGvaYrD' target='_blank'>変更・ウェイティング登録フォーム</ExternalLink>

      </StyledBasePortableText>
    </>
  )
}
export default Registration
