import React from 'react'
import Container from '../../../components/container'
import GraphQLErrorList from '../../../components/graphql-error-list'
import Heading from '../../../components/heading'
import Seo from '../../../components/seo'
import Summer2020Registration from '../../../components/summer-2020-registration'
import Summer2020GetClasses from '../../../components/summer-2020-getclasses-form'
import Layout from '../../../containers/layout'

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Heading ja='小学部クラス登録・確認' en='Class Registration' />
        <Summer2020Registration />
        <Summer2020GetClasses />
      </Container>
    </Layout>
  )
}

export default IndexPage
